import { Stack, Button, useTheme } from "@mui/material";
import LoginImage from "../../images/LoginImage.png";
import { onEnterKey } from "../../utilities/keyboardKey";
import { COLORS } from "../../utilities/theme";
import CheckBoxInput from "../inputs/CheckBoxInput";
import FormInput from "../inputs/FormInput";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";
import useLoginFormCard from "../../hooks/components/useLoginFormCard";
import useScreenSize from "../../hooks/utils/useScreenSize";

const LoginFormCard = () => {
    const theme = useTheme();
    const { xs, sm, xsOrsm } = useScreenSize();
    const {
        control,
        scrumMaster,
        toggleScrumMaster,
        login,
        loginGuest,
        onEnterFocus,
        setInputRef,
    } = useLoginFormCard();

    return (
        <Stack
            bgcolor={theme.palette.background.paper}
            width={{ xs: "100%", md: "80vw" }}
            flex={{ xs: 1, md: undefined }}
            direction={{ xs: "column", md: "row" }}
            p={{ xs: 2, md: 4 }}
        >
            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                flex={{ xs: 0.5, sm: 0.25, md: 0.6 }}
            >
                <img
                    // height={sm ? "50%" : undefined}
                    width={sm ? "50%" : "90%"}
                    src={LoginImage}
                    alt=""
                />
            </Stack>
            <Stack
                flex={{ xs: 0.5, sm: 0.75, md: 0.4 }}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Stack
                    spacing={{ xs: 1, sm: 3, md: 4 }}
                    height={"95%"}
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Stack py={{ xs: 2, md: 0 }}>
                        <Text
                            size={xs ? TextSize.Largest : TextSize.Humoungous}
                            bold
                        >
                            Agile Assistant
                        </Text>
                    </Stack>

                    <Stack width={"100%"} spacing={2}>
                        <FormInput
                            inputRef={setInputRef("username")}
                            control={control}
                            name="userName"
                            label="Username"
                            rules={{ required: "Missing username" }}
                            onKeyPress={onEnterFocus("password")}
                        />
                        <FormInput
                            inputRef={setInputRef("password")}
                            control={control}
                            name="password"
                            label="Password"
                            rules={{ required: "Missing password" }}
                            type="password"
                            onKeyPress={onEnterKey(login)}
                        />
                        <CheckBoxInput
                            checked={scrumMaster}
                            onClick={toggleScrumMaster}
                            name="Login as Scrum Master"
                        />
                    </Stack>

                    <Stack spacing={{ xs: 1, md: 2 }} width={"100%"}>
                        <Button onClick={login} variant="contained" fullWidth>
                            Log In
                        </Button>
                        {/*<Stack*/}
                        {/*    direction={"row"}*/}
                        {/*    alignItems={"center"}*/}
                        {/*    justifyContent={"center"}*/}
                        {/*    spacing={2}*/}
                        {/*>*/}
                            {/*<Stack*/}
                            {/*    width={"100%"}*/}
                            {/*    sx={{*/}
                            {/*        borderWidth: 0,*/}
                            {/*        borderBottomWidth: 1,*/}
                            {/*        borderStyle: "solid",*/}
                            {/*        borderColor: COLORS.grey,*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<Text*/}
                            {/*    color={COLORS.darkGrey}*/}
                            {/*    size={TextSize.Medium}*/}
                            {/*>*/}
                            {/*    Or*/}
                            {/*</Text>*/}
                            {/*<Stack*/}
                            {/*    width={"100%"}*/}
                            {/*    sx={{*/}
                            {/*        borderWidth: 0,*/}
                            {/*        borderBottomWidth: 1,*/}
                            {/*        borderStyle: "solid",*/}
                            {/*        borderColor: COLORS.grey,*/}
                            {/*    }}*/}
                            {/*/>*/}
                        {/*</Stack>*/}
                        {/*<Button*/}
                        {/*    onClick={loginGuest}*/}
                        {/*    variant="outlined"*/}
                        {/*    fullWidth*/}
                        {/*>*/}
                        {/*    Log In as Guest*/}
                        {/*</Button>*/}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default LoginFormCard;
