import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useErrorState from "../utils/useErrorState";
import useAxios from "./useAxios";
import { IUser } from "../../api/IUser";
import { useUserStore } from "../../stores/useUserStore";
import { useShowFormError } from "../utils/useShowFormError";
import useJWTCookie from "./useJWTCookie";
import useIsOpen from "../utils/useIsOpen";
import { useNavigate, useParams } from "react-router-dom";
import { toString } from "../../utilities/stringify";

const useLogin = () => {
    const navigate = useNavigate();
    const meetingId = (useParams() as { meetingId?: string } | undefined)
        ?.meetingId;

    const setUser = useUserStore((state) => state.setUser);

    const [scrumMaster, _, __, toggleScrumMaster] = useIsOpen();

    const { post, get } = useAxios();
    const { remove, setJwt, getJwt } = useJWTCookie();
    const {
        control,
        formState: { errors, submitCount },
        handleSubmit,
        clearErrors,
    } = useForm({
        defaultValues: {
            userName: "",
            password: "",
        },
    });
    useShowFormError(errors, submitCount, clearErrors);

    const toHome = () => {
        navigate(meetingId ? `/${meetingId}` : "/");
    };

    const login = handleSubmit(async (data) => {
        let res = await post<IUser>(`/api/user/login`, {
            userName: data.userName,
            password: data.password,
            scrumMaster,
        });
        if (!res) return;

        setJwt(res.refreshToken);
        setUser(res);
        toHome();
    });

    const loginGuest = async () => {
        let res = await post<IUser>(`/api/user/login`, {
            userName: "",
            password: "",
            isGuest: true,
            scrumMaster,
        });
        if (!res) return;

        setJwt(res.refreshToken);
        setUser(res);
        toHome();
    };

    const tokenLogin = async () => {
        let [token] = await getJwt();
        if (!token) return;

        let res = await get<IUser>(`/api/user/refresh`, token);

        if (!res) return navigate("/login");

        setJwt(res.refreshToken);
        setUser(res);
    };

    return {
        control,
        scrumMaster,

        toggleScrumMaster,
        login,
        loginGuest,
        tokenLogin,
    };
};

export default useLogin;
