import { ArrowBack, Send, Settings, Style } from "@mui/icons-material";
import { Stack, Paper, IconButton, Button } from "@mui/material";
import React from "react";
import { theme, COLORS } from "../../utilities/theme";
import SquareIconButton from "../SquareIconButton";
import { TextSize } from "../utils/TextSize";
import Text from "../Text";
import { useNavigate } from "react-router-dom";
import useLeaveMeetingPage from "../../hooks/utils/useLeaveMeetingPage";
import MenuInput from "../inputs/MenuInput";
import { SelectItem } from "../inputs/SelectInput";
import IParticipant from "../../api/IParticipant";
import useScreenSize from "../../hooks/utils/useScreenSize";
import { cutString } from "../../utilities/stringify";

type ParticipantPageHeaderProps = {
    deckOptions: SelectItem[];
    participant: IParticipant;
    title?: string;
    onArrowBack?: () => void;
    onDeck?: (v: string) => any | Promise<any>;
};

const ParticipantPageHeader = ({
    deckOptions,
    participant,
    title,
    onArrowBack,
    onDeck,
}: ParticipantPageHeaderProps) => {
    const { xsOrsm } = useScreenSize();
    return (
        <Stack width={"100%"}>
            <Paper elevation={2}>
                <Stack
                    bgcolor={theme.palette.background.paper}
                    padding={2}
                    direction={"row"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Stack
                        spacing={2}
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <IconButton onClick={onArrowBack}>
                            <ArrowBack />
                        </IconButton>
                        <IconButton
                            sx={{
                                borderRadius: 1,
                                opacity: 0,
                                bgcolor: COLORS.grey,
                                cursor: "default",
                            }}
                        >
                            <Send />
                        </IconButton>
                    </Stack>

                    <Stack alignItems={"center"} justifyContent={"center"}>
                        <Text bold>Voting On: {title}</Text>
                        <Text size={TextSize.Small}>
                            {xsOrsm
                                ? cutString(participant.name, 7)
                                : participant.name}
                            :{participant.group}
                        </Text>
                    </Stack>

                    <Stack
                        spacing={2}
                        direction={"row"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        maxWidth={"25%"}
                    >
                        <MenuInput
                            title="Choose Deck"
                            textSize={xsOrsm ? TextSize.Small : undefined}
                            options={deckOptions}
                            value={participant.deckId}
                            onItem={(v) => onDeck && onDeck(v.value)}
                            ButtonProps={{
                                color: "info",
                                variant: "text",
                                endIcon: <Style />,
                                startIcon: undefined,
                            }}
                        />
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    );
};

export default ParticipantPageHeader;
